import { SERVER_HTTPS_ENDPOINT } from "../config/config";
import { ServerClient } from "./ServerClient";
import { IProjectsDTO } from "./ProjectDTO";

export class ProjectsClient extends ServerClient {

  constructor(token: string = "") {
    super(token);
  }

  async get(dto: IProjectsDTO): Promise<IProjectsDTO> {
    if (! dto.projectUUID) {
      throw new Error("ERROR: projectUUID must be provided");
    }
    console.debug('projects client - get - ', dto.projectUUID);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/projects/get?projectUUID=${encodeURIComponent(dto.projectUUID)}`;
    return await this.fetchJSONCall(url, this.getOptions(url));
  }

  async create(dto: IProjectsDTO): Promise<IProjectsDTO> {
    console.debug('projects client - create - ', dto.projectUUID);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/projects/create`;
    return await this.fetchJSONCall(url, this.postOptions(url, dto));
  }

  async update(dto: IProjectsDTO | null): Promise<IProjectsDTO> {
    console.debug('projects client - update - ', dto?.projectUUID);

    const url = `${SERVER_HTTPS_ENDPOINT}/api/projects/update`;
    return await this.fetchJSONCall(url, this.postOptions(url, dto));
  }

  async delete(dto: IProjectsDTO): Promise<IProjectsDTO> {
    console.debug('projects client - delete - ', dto.projectUUID);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/projects/delete`;
    return await this.fetchJSONCall(url, this.postOptions(url, dto));
  }
}
