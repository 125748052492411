import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import {
  Auth0ProviderWrapper,
  Auth0LoginWrapper,
  APP_NAME,
} from "@azulejo/common";

const LandingPage = React.lazy(
  () => import(`./${APP_NAME}/view/pages/LandingPage`)
);
const ProjectsPage = React.lazy(
  () => import(`./${APP_NAME}/view/pages/ProjectsPage`)
);
const AlbumPage = React.lazy(
  () => import(`./${APP_NAME}/view/pages/AlbumPage`)
);
const AlbumsPage = React.lazy(
  () => import(`./${APP_NAME}/view/pages/AlbumsPage`)
);
const CalendarPage = React.lazy(
  () => import(`./${APP_NAME}/view/pages/CalendarPage`)
);
const AboutPage = React.lazy(
  () => import(`./${APP_NAME}/view/pages/AboutPage`)
);

export const ROOT_PATH = "/";

export const APP_PATH = `/${APP_NAME}`;
export const PROJECTS_PATH = APP_PATH;
export const ALBUM_PATH = `${APP_PATH}/album`;
export const ALBUMS_PATH = `${APP_PATH}/albums`;
export const CALENDAR_PATH = `${APP_PATH}/calendar`;
export const ABOUT_PATH = `${APP_PATH}/about`;

const AppRouter = createBrowserRouter([
  {
    path: APP_PATH,
    element: <Auth0ProviderWrapper component={<Auth0LoginWrapper />} />,
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ProjectsPage />
          </Suspense>
        ),
      },
      {
        path: `${ALBUMS_PATH}/:projectUUID`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AlbumsPage />
          </Suspense>
        ),
      },
      {
        path: `${ALBUM_PATH}/:projectUUID/:eventUUID`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AlbumPage />
          </Suspense>
        ),
      },
      {
        path: `${CALENDAR_PATH}/:projectUUID`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <CalendarPage />
          </Suspense>
        ),
      },
      {
        path: `${ABOUT_PATH}/:projectUUID`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AboutPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: `${ROOT_PATH}/about`,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AboutPage />
      </Suspense>
    ),
  },
  {
    path: `${ROOT_PATH}/calendar`,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CalendarPage />
      </Suspense>
    ),
  },
  {
    path: ROOT_PATH,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <LandingPage />
      </Suspense>
    ),
  },
]);

export default AppRouter;
