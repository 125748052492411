import { action, makeObservable, observable } from 'mobx';

class MenuModel {
  isDrawerOpen: boolean = false;

  constructor() {
    makeObservable(this, {
      isDrawerOpen: observable,
      setIsDrawerOpen: action,
    });
  }

  setIsDrawerOpen = (isOpen: boolean): void => {
    this.isDrawerOpen = isOpen;
  };
}

export const menuModel = new MenuModel();
