import { makeAutoObservable } from 'mobx';
import { toJS } from 'mobx';
import { TimelineDTO, TimelineEventDTO } from './TimelineDTO';
import { TimelineClient } from './TimelineClient';

export class TimelineModel {
  private timeline: TimelineDTO | null = null;
  private timelines: TimelineDTO[] = [];
  private timelineClient = new TimelineClient();

  constructor() {
    makeAutoObservable(this);
  }

  getTimelines = (): TimelineDTO[] => {
    return toJS(this.timelines);
  }

  getTimeline = (): TimelineDTO | null => {
    return toJS(this.timeline);
  }

  getTimelineEventsSorted = (): TimelineEventDTO[] => {
    const timelineCopy: TimelineDTO | null = this.getTimeline();
    if (!timelineCopy || !timelineCopy.events)
      return [];

    let i = 0;
    timelineCopy.events.forEach((evnt: TimelineEventDTO) => evnt.index = i++);

    const timelineEvents: TimelineEventDTO[] = timelineCopy.events.sort((a, b) => {
      const left: Date = new Date(a.from);
      const right: Date = new Date(b.from);
      if (left < right) 
        return 1;
      if (left === right) 
        return 0;
      return -1;
    });

    return timelineEvents;
  };

  deleteTimelineEvent = (index: number): void => {
    const timelineEventsCopy: TimelineEventDTO[] = this.getTimelineEventsSorted();
    timelineEventsCopy?.splice(index, 1);
    this.setTimelineEvents(timelineEventsCopy);
  }

  setTimeline = (timeline: TimelineDTO | null): void => {
    this.timeline = timeline;
  };

  setTimelines = (timelines: Array<TimelineDTO>): void => {
    this.timelines = timelines;
  };

  private getTimelineByUUID = (timelineUUID: string | undefined): TimelineDTO | null => {  
    const timelines = this.getTimelines();
    const filteredTimelines: TimelineDTO[] = timelines.filter(item => item.timelineUUID === timelineUUID);
    return filteredTimelines.length > 0 ? filteredTimelines[0] : null;
  }

  selectTimeline = (timelineUUID: string | undefined) => {
    const timeline: TimelineDTO | null = this.getTimelineByUUID(timelineUUID);  
    this.setTimeline(timeline);
  };

  setTimelineEvents = async (events: TimelineEventDTO[]) => {
    let timelineCopy: TimelineDTO | null = this.getTimeline();
    if (!timelineCopy)
      throw new Error("timeline is null");
    timelineCopy.events = events;
    await this.timelineClient.update(timelineCopy);
    this.setTimeline(timelineCopy);
  }

  setDescription = async (text: string, index: number): Promise<void> => {
    const timelineCopy: TimelineDTO | null = this.getTimeline();
    if (! timelineCopy?.events)
      throw new Error('Timeline events are null');

    timelineCopy.events[index].description = text;
    await this.timelineClient.update(timelineCopy);
    this.setTimeline(timelineCopy);
  };

  setTimelineEventMediaURL = async (url: string, s3URL: string, index: number): Promise<void> => {
    const timelineCopy: TimelineDTO | null = this.getTimeline();
    if (!timelineCopy?.events || index >= timelineCopy?.events.length) 
      throw new Error("timeline events dont exist");

    timelineCopy.events[index].mediaURL = url;
    timelineCopy.events[index].s3MediaURL = s3URL;
    await this.timelineClient.update(timelineCopy);
    this.setTimeline(timelineCopy);
  };
}

export const timelineModel = new TimelineModel();
