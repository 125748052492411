import { PaletteMode } from "@mui/material";
import { action, makeObservable, observable } from 'mobx';

class ModeModel {
    mode: PaletteMode = "dark";
    showCustomTheme: boolean = true;

  constructor() {
    makeObservable(this, {
      mode: observable,
      setMode: action,
      toggleMode: action,
      showCustomTheme: observable,
      setShowCustomTheme: action,
      toggleShowCustomTheme: action
    });
  }

  setMode = (mode: any): void => {
    this.mode = mode;
  };

  setShowCustomTheme = (showCustomTheme: boolean): void => {
    this.showCustomTheme = showCustomTheme;
  }

  callbackSetMode = (mode: any) => () => {
    this.setMode(mode);
  };

  callbackSetShowCustomTheme = (showCustomTheme: boolean) => () => {
    this.setShowCustomTheme(showCustomTheme);
  }

  toggleMode = () => {
    this.mode === "dark" ? this.setMode("light") : this.setMode("dark");
  };

  toggleShowCustomTheme = () => {
    this.setShowCustomTheme(!this.showCustomTheme);
  };
}

export const modeModel = new ModeModel();