import { SERVER_HTTPS_ENDPOINT } from "../config/config";
import { ServerClient } from "./ServerClient";
import { TimelineDTO } from "./TimelineDTO";
import { TimelineEventDTO } from "./TimelineDTO";

export class TimelineClient extends ServerClient {

  constructor(token: string = "") {
    super(token);
  }

  async get(dto: TimelineDTO): Promise<TimelineDTO> {
    if (! dto.timelineUUID) {
      throw new Error("ERROR: timelineUUID must be provided");
    }
    console.debug('timeline client - get - ', dto.timelineUUID);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/timeline/get?timelineUUID=${encodeURIComponent(dto.timelineUUID)}`;
    return await this.fetchJSONCall(url, this.getOptions(url));
  }

  async create(dto: TimelineDTO): Promise<TimelineDTO> {
    console.debug('timeline client - create - ', dto.timelineUUID);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/timeline/create`;
    return await this.fetchJSONCall(url, this.postOptions(url, dto));
  }

  async update(dto: TimelineDTO | null): Promise<TimelineDTO> {
    console.debug('timeline client - update - ', dto?.timelineUUID);

    const deepCopyDTO: TimelineDTO = JSON.parse(JSON.stringify(dto));
    deepCopyDTO.events?.forEach((evnt: TimelineEventDTO, index: number) => {
      evnt.mediaURL = evnt.s3MediaURL;
    });

    const url = `${SERVER_HTTPS_ENDPOINT}/api/timeline/update`;
    return await this.fetchJSONCall(url, this.postOptions(url, deepCopyDTO));
  }

  async delete(dto: TimelineDTO): Promise<TimelineDTO> {
    console.debug('timeline client - delete - ', dto.timelineUUID);
    const url = `${SERVER_HTTPS_ENDPOINT}/api/timeline/delete`;
    return await this.fetchJSONCall(url, this.postOptions(url, dto));
  }
}
