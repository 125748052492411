import { makeAutoObservable } from 'mobx';
import { toJS } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { AlbumClient } from './AlbumClient';
import { AlbumDTO } from './AlbumDTO';

class AlbumModel {
  private albums: AlbumDTO[] = [];
  private album: AlbumDTO | null = null;
  private albumsClient: AlbumClient = new AlbumClient();

  constructor() {
    makeAutoObservable(this);
  }

  setAlbums = (albums: Array<AlbumDTO>): void => {
    this.albums = albums;
  }

  private setAlbum = (album: AlbumDTO): void => {
    this.album = album;
  };

  setName = async (name: string): Promise<void> => {
    const albumCopy: AlbumDTO | null = this.get();
    if (! albumCopy)
      throw new Error('Album is null');

    albumCopy.eventName = name;
    await this.albumsClient.update(albumCopy);
  };

  create = async (email: string | undefined): Promise<AlbumDTO> => {
    const albumsCopy = this.getAlbums();
    const albumDTO: AlbumDTO = {
      eventUUID: uuidv4(),
      eventName: "New Album",
      email: email,
    };
    albumsCopy.push(albumDTO);
    await this.albumsClient.create(albumDTO);
    this.setAlbums(albumsCopy);

    return albumDTO;
  }

  delete = async (albumUUID: string): Promise<void> => {
    const albumDTO: AlbumDTO = {
      eventUUID: albumUUID,
    };
    await this.albumsClient.delete(albumDTO);

    const albumsCopy: AlbumDTO[] = this.getAlbums();
    const index: number = albumsCopy.findIndex((album: AlbumDTO) => { return album.eventUUID === albumUUID });
    albumsCopy?.splice(index, 1);
    this.setAlbums(albumsCopy);
  }

  get = (): AlbumDTO | null => {
    return toJS(this.album);
  }

  getAlbums = (): AlbumDTO[] => {
    return toJS(this.albums);
  }

  getName = (): string | undefined => {
    return this.get()?.eventName;
  };

  getUUID = (): string | undefined => {
    return this.get()?.eventUUID;
  }

  getImageURLs = (): string[] | undefined => {
    return this.get()?.local?.imageURLs;
  }

  getThumbnailURLs = (): string[] | undefined => {
    return this.get()?.local?.imageThumbURLs;
  }

  addImage = async (fileName: string) => {
    if (!this.get()) {
      throw new Error("Album is null while updating album");
    }

    let albumCopy = toJS(this.get());
    if (!albumCopy) {
      throw new Error("album is null");
    }

    if (!albumCopy?.data) {
      const data: { imageNames: string[] } = {
        imageNames: [],
      };
      albumCopy.data = data;
    }

    albumCopy?.data?.imageNames.unshift(fileName);
    albumCopy = await this.loadThumbnailURL(albumCopy, 0);
    albumCopy = await this.loadImageURL(albumCopy, 0);

    await this.albumsClient.update(albumCopy);

    this.setAlbum(albumCopy);
  }

  deleteImage = async (imageIndex: number): Promise<boolean | void> => {
    let albumCopy: AlbumDTO | null = toJS(this.get());
    if (!albumCopy) {
      throw new Error('album is null');
    }
    albumCopy?.data?.imageNames.splice(imageIndex, 1);
    albumCopy?.local?.imageThumbURLs?.splice(imageIndex, 1);
    albumCopy?.local?.imageURLs?.splice(imageIndex, 1);
    albumCopy = this.resetCoverImage(albumCopy, imageIndex);

    if (!albumCopy) {
      throw new Error("album should not be null");
    }

    await this.albumsClient.update(albumCopy);

    this.setAlbum(albumCopy);
  }

  selectAlbum = (albumUUID: string | undefined) => {
    const album: AlbumDTO = this.getAlbums().filter(item => item.eventUUID === albumUUID)[0];     
    this.setAlbum(album);
  }

  loadThumbnailURL = async (album: AlbumDTO, imageIndex: number): Promise<AlbumDTO> => {
    const iconURL: string = await this.albumsClient.fetchThumbnailURL(album, imageIndex);

    let urls = album?.local?.imageThumbURLs;
    if (!urls) {
      urls = [iconURL];
    } else if (imageIndex >= urls?.length) {
      urls?.push(iconURL);
    } else {
      urls?.splice(imageIndex, 0, iconURL);
    }
    album.local = {
      ...album.local,
      imageThumbURLs: urls
    }

    if (imageIndex == 0) {
      album.local = {
        ...album.local,
        coverImageURL: urls[0]
      };
    }

    return album;
  };

  loadImageURL = async(album: AlbumDTO, imageIndex: number): Promise<AlbumDTO> => {
    const iconURL: string = await this.albumsClient.fetchURL(album, imageIndex);
    let urls = album?.local?.imageURLs;
    if (!urls) {
      urls = [iconURL];
    } else if (imageIndex >= urls?.length) {
      urls?.push(iconURL);
    } else {
      urls?.splice(imageIndex, 0, iconURL);
    }
    album.local = {
      ...album.local,
      imageURLs: urls
    }

    return album;
  };

  private resetCoverImage = (albumCopy: AlbumDTO | null, index: number): AlbumDTO | null => {
    if (index === 0 && albumCopy?.local?.coverImageURL) {
      const imageThumbURLs = albumCopy?.local?.imageThumbURLs;
      if (albumCopy?.local?.imageThumbURLs && imageThumbURLs && imageThumbURLs.length > 0) {
        albumCopy.local.coverImageURL = albumCopy.local.imageThumbURLs[0];
      }
    }
    return albumCopy;
  }
}

export const albumModel = new AlbumModel();
