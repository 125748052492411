import MenuItem from "@mui/material/MenuItem";
import { menuModel } from "../../model/MenuModel";

export const AppBarMenuItem = (props: any) => {
  const name: string = props.name;
  const onClick: any = props.onClick;
  const { setIsDrawerOpen } = menuModel;

  return (
    <MenuItem
      onClick={() => {
        onClick();
        setIsDrawerOpen(false);
      }}
    >
      {name}
    </MenuItem>
  );
};
