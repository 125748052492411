import { makeAutoObservable } from 'mobx';
import { toJS } from 'mobx';
import { albumModel } from './AlbumModel';
import { IProjectsDTO } from './ProjectDTO';
import { ProjectsClient } from './ProjectsClient';
import { AlbumDTO } from './AlbumDTO';

class ProjectModel {
  private project: IProjectsDTO | null = null;
  private projects: IProjectsDTO[] = [];
  private projectsClient = new ProjectsClient();

  constructor() {
    makeAutoObservable(this);
  }

  getProjects = (): IProjectsDTO[] => {
    return toJS(this.projects);
  }

  getProject = (): IProjectsDTO | null => {
    return toJS(this.project);
  }

  setProject = (project: IProjectsDTO | null): void => {
    this.project = project;
  };

  setProjects = (projects: Array<IProjectsDTO>): void => {
    this.projects = projects;
  };

  private getProjectByUUID = (projectUUID: string | undefined): IProjectsDTO => {  
    const projs = this.getProjects();
    return projs.filter(item => item.projectUUID === projectUUID)[0];
  }

  selectProject = (projectUUID: string | undefined) => {
    const proj: IProjectsDTO = this.getProjectByUUID(projectUUID);  
    this.setProject(proj);
  };

  getImageURLs = (): string[] => {
    const albums: AlbumDTO[] = this.getAlbums();
    let imageURLs: string[] = [];
    albums.forEach((album: AlbumDTO) => {
      if (!album?.local?.imageURLs) return;
      imageURLs = [...imageURLs, ...album?.local?.imageURLs];
    });
    return imageURLs;
  }

  getImageS3URLs = (): string[] => {
    const albums: AlbumDTO[] = this.getAlbums();
    let imageS3URLs: string[] = [];
    albums.forEach((album: AlbumDTO) => {
      if (!album?.local?.imageS3URLs) return;
      imageS3URLs = [...imageS3URLs, ...album?.local?.imageS3URLs];
    });
    return imageS3URLs;
  }

  getThumbURLs = (): string[] => {
    const albums: AlbumDTO[] = this.getAlbums();
    let thumbURLs: string[] = [];
    albums.forEach((album: AlbumDTO) => {
      if (!album?.local?.imageThumbURLs) return;
      thumbURLs = [...thumbURLs, ...album?.local?.imageThumbURLs];
    });
    return thumbURLs;
  }

  getAlbums = (): AlbumDTO[] => {
    const project: IProjectsDTO | null = this.getProject();
    if (!project?.data?.eventsUUIDList) {
      return [];
    }
    const albumsList: string[] = project?.data?.eventsUUIDList;
    const albums: AlbumDTO[] = albumModel.getAlbums();
    const projectAlbums: AlbumDTO[] = albums.filter((album: AlbumDTO) => albumsList.includes(album?.eventUUID ? album.eventUUID : "none"));
    return projectAlbums;
  }

  addAlbum = async (email: string | undefined) => {
    const albumDTO: AlbumDTO = await albumModel.create(email);
    if (!albumDTO.eventUUID) {
      throw new Error("albumUUID is undefined");
    }

    const project: IProjectsDTO | null = this.getProject();
    project?.data?.eventsUUIDList?.push(albumDTO.eventUUID);
    await this.projectsClient.update(project);
    this.setProject(project);
  };

  deleteAlbum = async (albumUUID: string) => {
    await albumModel.delete(albumUUID);

    const project: IProjectsDTO | null = this.getProject();
    const index: number | undefined = project?.data?.eventsUUIDList?.findIndex((albumID: string) => { return albumID === albumUUID });
    if (!index) {
      throw new Error("index is undefined");
    }
    project?.data?.eventsUUIDList?.splice(index, 1);
    
    await this.projectsClient.update(project);
    this.setProject(project);
  };

  setAbout = async (text: string): Promise<void> => {
    const projCopy: IProjectsDTO | null = this.getProject();
    if (! projCopy?.data)
      throw new Error('Project is null');

    projCopy.data.about = text;
    await this.projectsClient.update(projCopy);
  };
}

export const projectsModel = new ProjectModel();
