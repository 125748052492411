import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { CloseButton } from "../buttons/CloseButton";
import { SandwichMenuButton } from "../buttons/SandwichMenuButton";

export const AppBarMobileMenu = observer((props: any) => {
  const children: any = props.children;
  const isOpen: boolean = props.isOpen;
  const expandCallback: (open: boolean) => any = props.expandCallback;

  return (
    <>
      <SandwichMenuButton clickCallback={() => expandCallback(true)} />
      <Drawer anchor="top" open={isOpen} onClose={() => expandCallback(false)}>
        <Box sx={{ p: 2, backgroundColor: "background.default" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/*<ToggleColorMode
              mode={mode}
              toggleColorMode={toggleColorMode}
            />*/}
            <CloseButton closeCallback={() => expandCallback(false)} />
          </Box>
          <Divider sx={{ my: 3 }} />
          {children}
        </Box>
      </Drawer>
    </>
  );
});
