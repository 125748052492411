import { APP_NAME } from "../../config/config";

export class UnAuthenticatedParamResolver {
    private static isPublicPage = (projectUUID: string | undefined) => {
        return !projectUUID;
    };

    static resolveEmail(emailParam: string | undefined, projectUUIDParam: string | undefined) {
        return this.isPublicPage(projectUUIDParam) ? `${APP_NAME}@azulejo.io` : emailParam;
    }

    static resolveProjectUUID(projectUUIDParam: string | undefined) {    
        return this.isPublicPage(projectUUIDParam) ? APP_NAME : projectUUIDParam;
    }
}